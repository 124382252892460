import React from 'react';
import Helmet from 'react-helmet';
import { Header } from '../components/layout/index.js';
import { Section, Container } from '../components/common/index.js';
import { graphql } from 'gatsby';
const Success = ({ data }) => (
  <>
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page" />
    </Helmet>
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Thanks!"
      // propertyName="Base Magnetic Island"
      // caption="Sunset Walking Tour"
    />

    <Section>
      <Container>
        <p>Thank you for contacting us! We will be in touch shortly.</p>
      </Container>
    </Section>
  </>
);

export default Success;

export const query = graphql`
  query {
    file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
